import { put } from 'redux-saga/effects';
import loginApi from "../services/loginApi";

let api = new loginApi();

export function* paymentGetwaySaga({ payload, resolve }) {

    try {
        let data = yield api.PaymentGetway(payload);
        resolve && resolve(data)
    } catch (e) {
        alert(e)
    }
}