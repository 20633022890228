export const USER_REGISTER = "USER_REGISTER"
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS"
export const USER_REGISTER_FAILDE = "USER_REGISTER_FAILDE"


export const FETCH_USER = "FETCH_USER"
export const FETCH_USER_RESPONSE = "FETCH_USER_RESPONSE"
export const FETCH_USER_FAILDE = "FETCH_USER_FAILDE"

export const LOGOUT = 'LOGOUT'
export const LOGOUT_RESPONSE = 'LOGOUT_RESPONSE'
export const LOGOUT_FAILED = 'LOGOUT_FAILED'

export const FETCH_MASTER = "FETCH_MASTER"
export const FETCH_MASTER_RESPONSE = "FETCH_MASTER_RESPONSE"
export const FETCH_MASTER_FAILDE = "FETCH_MASTER_FAILDE"