export const PAN_VERIFICATION = "PAN_VERIFICATION"

export const FETCH_MEMBER_SHIP = "FETCH_MEMBER_SHIP"
export const FETCH_MEMBER_SHIP_RESPONSE = "FETCH_MEMBER_SHIP_RESPONSE"
export const FETCH_MEMBER_SHIP_FAILED = "FETCH_MEMBER_SHIP_FAILED"


export const ADD_PARTNER_SUBSCRIPTION = "ADD_PARTNER_SUBSCRIPTION"
export const PARTNER_SUBSCRIPTION_RESPONSE = "PARTNER_SUBSCRIPTION_RESPONSE"
export const PARTNER_SUBSCRIPTION_FAILED = "PARTNER_SUBSCRIPTION_FAILED"

export const FETCH_SUBSCRIPTION_ORDERDATA = "FETCH_SUBSCRIPTION_ORDERDATA"
export const SUBSCRIPTION_ORDERDATA_RESPONSE = "SUBSCRIPTION_ORDERDATA_RESPONSE"
export const SUBSCRIPTION_ORDERDATA_FAILED = "SUBSCRIPTION_ORDERDATA_FAILED"