import { put } from 'redux-saga/effects';
import loginApi from "../services/loginApi";
import { FETCH_CONTENT_BYSLUG_FAILED, FETCH_CONTENT_BYSLUG_RESPONSE, FETCH_CONTENT_DATA_FAILED, FETCH_CONTENT_DATA_RESPONSE } from '../redux/types/cmsContentType';

let api = new loginApi();



export function* cmsContentSaga({ payload, resolve }) {

    try {
        let data = yield api.Cmspagecontent(payload);
        if (data.status === 200) {
            yield put({ type: FETCH_CONTENT_DATA_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            console.log("FETCH_CONTENT_DATA_FAILED", data);
            yield put({ type: FETCH_CONTENT_DATA_FAILED, payload: data })
            resolve && resolve(data)
        }
    } catch (e) {
        yield put({ type: FETCH_CONTENT_DATA_FAILED, payload: e })

    }
}


export function* cmsContentBySlugSaga({ payload, resolve }) {

    try {

        console.log("cmsContentBySlugSaga",payload)
        let data = yield api.Cmspagecontentbyslug(payload);
        if (data.status === 200) {
            yield put({ type: FETCH_CONTENT_BYSLUG_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            console.log("FETCH_CONTENT_BYSLUG_FAILED", data);
            yield put({ type: FETCH_CONTENT_BYSLUG_FAILED, payload: data })
            resolve && resolve(data)
        }
    } catch (e) {
        yield put({ type: FETCH_CONTENT_BYSLUG_FAILED, payload: e })

    }
}