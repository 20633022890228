export const FETCH_PRODUCTS_DATA = "FETCH_PRODUCTS_DATA";
export const RESPONSE_PRODUCTS_DATA = "RESPONSE_PRODUCTS_DATA";
export const FAILED_PRODUCTS_DATA = "FAILED_PRODUCTS_DATA";


export const FETCH_NEW_ARRIVAL_PRODUCT = "FETCH_NEW_ARRIVAL_PRODUCT";
export const RESPONSE_NEW_ARRIVAL_PRODUCT = "RESPONSE_NEW_ARRIVAL_PRODUCT";
export const FAILED_NEW_ARRIVAL_PRODUCT = "FAILED_NEW_ARRIVAL_PRODUCT";


export const FETCH_FILTER_PRODUCTS_DATA = "FETCH_FILTER_PRODUCTS_DATA";
export const RESPONSE_FILTER_PRODUCTS_DATA = "RESPONSE_FILTER_PRODUCTS_DATA";
export const FAILED_FILTER_PRODUCTS_DATA = "FAILED_FILTER_PRODUCTS_DATA";

export const FETCH_PRODUCTS_DETAILS = "FETCH_PRODUCTS_DETAILS";
export const RESPONSE_PRODUCTS_DETAILS = "RESPONSE_PRODUCTS_DETAILS";
export const FAILED_PRODUCTS_DETAILS = "FAILED_PRODUCTS_DETAILS";