import * as type from '../types/cmsContentType'

export function fetchContentAction(){
    
    return {
        type: type.FETCH_CONTENT_DATA,
    }
}


export function fetchContentBySlugAction(data){
    
    return {
        type: type.FETCH_CONTENT_BYSLUG,
        payload: data
    }
}
