import * as type from '../types/cmsContentType';


const initialState = {
    content : null,
    singalContent : null
}

const contentReducer = (state = initialState, action) => {
    switch (action.type) {
        case type.FETCH_CONTENT_DATA_RESPONSE :{
            const { cmsPage } = action.payload.data
            return {...state, content: cmsPage };
        }
        case type.FETCH_CONTENT_DATA_FAILED :{
            return {...state, user: null };
        }

        case type.FETCH_CONTENT_BYSLUG_RESPONSE :{
            const { cmsPage } = action.payload.data
            return {...state, singalContent: cmsPage };
        }
        case type.FETCH_CONTENT_BYSLUG_FAILED :{
            return {...state, singalContent: null };
        }
        default:
            return state
    }
}
    

export default contentReducer;