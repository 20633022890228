import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { fetchContentAction, fetchContentBySlugAction } from '../../../redux/actions/contentAction';

const TermsAndConditionsL = () => {

    const dispatch = useDispatch();
    const location = useLocation()


    const { content, singalContent } = useSelector((state) => state.content)

    const [termsAndConditions, setTermAndCondition] = useState(null);
    const [active, setActive] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(fetchContentAction());
    }, [])

    useEffect(() => {
        const { pathname } = location
        let data = pathname.split("/").splice(2,1).join()
        console.log("sluge name", data)
        dispatch(fetchContentBySlugAction({"slugName": data}));
    }, [location])


    useEffect(() => {
        if(singalContent){
            singalContent?.forEach((item) => {
                setTermAndCondition(item.cmsPageContentData)
                setActive(item.pageRoute)
            })
        }
    }, [singalContent])

    console.log("singalContent", content)

    return (
        <div className='wrapper terms-and-conditions'>

            <div className='banner-div'>
                <div className='container'>
                    <h1>Terms And Conditions</h1>
                </div>
            </div>

            <div className="container">

                <section className="all">
                    <div className="container">

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                                <li className="breadcrumb-item active">Terms And Conditions</li>
                            </ol>
                        </nav>

                        <div className="main">
                            <ul className="filter">
                                {
                                    content?.map((item,index) => {
                                        return (
                                            <li key={index} className={ item.pageRoute === active ? "current" : "one"}>
                                                <a href={`/t&c/${item.pageSlugName}`} ><span>{item.pageName}</span></a>
                                            </li>
                                        )
                                    })
                                }
                                {/* <li className="one"><Link to={'/orders-and-returns'}> <span>Orders And Returns</span></Link></li>
                                <li className="one"><Link to={'/payment-policy'}> <span>Payment Policy</span></Link></li>
                                <li className="one"><Link to={'/shipping-policy'}> <span>Shipping Policy</span></Link></li>
                                <li className="current"><Link to={'/'}> <span>Terms And Condition</span></Link></li>
                                <li className="one"><Link to={'/privacy-policy'}> <span>Privacy Policy</span></Link></li>
                                <li className="one"><Link to={'/cookie-policy'}> <span>Cookie Policy</span></Link></li> */}
                            </ul>

                            <div className='boxGroup'>
                                <div className="content">
                                    <div className="para">
                                        {
                                            termsAndConditions?.map((item, index) => {
                                                return (
                                                <div className="para" key={index}>
                                                    {item.pageContent ? 
                                                    <div dangerouslySetInnerHTML={{__html: item.pageContent}} />
                                                    : null }
                                                </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}


export default TermsAndConditionsL;