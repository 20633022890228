import React from 'react'
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './components';
import { store, persistor }  from './redux/store'
import { SkeletonTheme } from 'react-loading-skeleton';
import { PersistGate } from 'redux-persist/integration/react';
// import swDev from './swDev';#4444442b
import './assets/Scss/mediaStyle.css';
import './assets/Scss/style.scss';

// const highlightColor = {
//     backgroundImage: (to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05))
// }


createRoot(document.getElementById('root'))
.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <SkeletonTheme baseColor="#dfd5d557" highlightColor="#4444442b">
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </SkeletonTheme>
            </PersistGate>
        </Provider>
     </React.StrictMode>
)

reportWebVitals();
// swDev();