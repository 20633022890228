export const ACTIVE_CART_VIEW = "ACTIVE_CART_VIEW"

export const ADD_TO_CART = "ADD_TO_CART"
export const ADD_TO_CART_RESPONSE = "ADD_TO_CART_RESPONSE"
export const ADD_TO_CART_FAILED = "ADD_TO_CART_FAILED"

export const REMOVE_TO_CART = "REMOVE_TO_CART"
export const REMOVE_TO_CART_RESPONSE = "REMOVE_TO_CART_RESPONSE"
export const REMOVE_TO_CART_FAILED = "REMOVE_TO_CART_FAILED"

export const LIST_TO_CART = "LIST_TO_CART"
export const LIST_TO_CART_RESPONSE = "LIST_TO_CART_RESPONSE"
export const LIST_TO_CART_FAILED = "LIST_TO_CART_FAILED"

export const UPDATE_CART = "UPDATE_CART"
export const UPDATE_CART_RESPONSE = "UPDATE_CART_RESPONSE"
export const UPDATE_CART_FAILED = "UPDATE_CART_FAILED"

export const CLEAR_TO_CART = "CLEAR_TO_CART"
export const CART_RESPONSE = "CART_RESPONSE"
export const CART_FAILED = "CART_FAILED"

export const UNIT_OF_PURCHASE = "UNIT_OF_PURCHASE"

export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST"
export const ADD_TO_WISHLIST_RESPONSE = "ADD_TO_WISHLIST_RESPONSE"
export const ADD_TO_WISHLIST_FAILED = "ADD_TO_WISHLIST_FAILED"

export const WISHLIST_LIST = "WISHLIST_LIST"
export const WISHLIST_LIST_RESPONSE = "WISHLIST_LIST_RESPONSE"
export const WISHLIST_LIST_FAILED = "WISHLIST_LIST_FAILED"

export const REMOVE_TO_WISHLIST = "REMOVE_TO_WISHLIST"
export const REMOVE_TO_WISHLIST_RESPONSE = "REMOVE_TO_WISHLIST_RESPONSE"
export const REMOVE_TO_WISHLIST_FAILED = "REMOVE_TO_WISHLIST_FAILED"

export const ROUTE_TO_WISHLIST = "ROUTE_TO_WISHLIST"    

export const ACTIVE_SIMILAR_PRODUCT = "ACTIVE_SIMILAR_PRODUCT"