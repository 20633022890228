import React, {useEffect} from 'react';
import {  Route, Routes, useLocation  } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import loadable from '@loadable/component';
import { loadState } from '../utils/localstorage';
import { cartListAction } from '../redux/actions/cartAction';
import { sessionId } from './helper/method';
import TermsAndConditionsL from './Pages/Tearms&Conditions';

const Mycart = loadable(() => import('../components/Mycart'));
const Contact = loadable(() => import('./Pages/Contact'));
const NotFound = loadable(() => import('./Pages/NotFound'));
const Footer = loadable(() => import('../shared/footer'));
const ReviewCart = loadable(() => import('./Pages/Reviewcart'));
const Checkout = loadable(() => import('./Pages/checkout'));
const CheckoutReview = loadable(() => import('./Checkout/CheckoutReview'));
const Payment = loadable(() => import('../components/Checkout/payment'));
const Thankyou = loadable(() => import('../components/Thankyou'));
const Registration = loadable(() => import('./molecules/Registration'));
const Thank = loadable(() => import('../components/Pages/Thank'));
const RequestVideoCall = loadable(() => import('../components/Pages/RequestVideoCall'));
const ThankyouForTryAtHome = loadable(() => import('../components/Pages/ThankyouForTryAtHome'));
const BookingInformation = loadable(() => import('../components/molecules/BookingInformation'));
const ScheduleYourAppointment = loadable(() => import('../components/molecules/ScheduleYourAppointment'));
const VideoCallThankYou = loadable(() => import('../components/Pages/VideoCallThankYou'));
const MyAccount = loadable(() => import('../components/Pages/MyAccount'));
const OrdersAndReturns = loadable(() => import('../components/Pages/OrdersAndReturns'));
const PaymentPolicy = loadable(() => import('../components/Pages/PaymentPolicy'));
const ShippingPolicy = loadable(() => import('../components/Pages/ShippingPolicy'));
const TermsAndConditions = loadable(() => import('../components/Pages/TermsAndConditions'));
const PrivacyPolicy = loadable(() => import('../components/Pages/PrivacyPolicy'));
const CookiePolicy = loadable(() => import('../components/Pages/CookiePolicy'));
const Faqs = loadable(() => import('../components/Pages/Faqs'));
const CustomerSays = loadable(() => import('../components/Pages/CustomerSays'));
const CustomerService = loadable(() => import('../components/Pages/CustomerService'));
const AboutSummera = loadable(() => import('../components/Pages/AboutSummera'));
const FindStore = loadable(() => import('../components/Pages/FindStore'));
const WhatsNew = loadable(() => import('../components/Pages/WhatsNew'));
const SellOnSummera = loadable(() => import('./molecules/SellOnSummera'));
const SubscriptionPlan = loadable(() => import('./molecules/SellOnSummera/subscription-plan'));
const SellOnSummeraReview = loadable(() => import('./molecules/SellOnSummera/CheckoutReview'));
const SubscriptionThanks = loadable(() => import('./molecules/SellOnSummera/thankyou'));
const Customised = loadable(() => import('./molecules/CustomiseRequest'));
const CustomisedRequestThankYou = loadable(() => import('./molecules/CustomiseRequest/thank-you'));
const JewelleryCareGuide = loadable(() => import('../components/Pages/JewelleryCareGuide'));
const GemstoneCareGuide = loadable(() => import('../components/Pages/GemstoneCareGuide'));
const DiamondCareGuide = loadable(() => import('../components/Pages/DiamondCareGuide'));
const CustomiseRequest = loadable(() => import('../components/Pages/CustomiseRequest'));
const ShopByBrand = loadable(() => import('../components/molecules/ShopByBrand'));
const Brand = loadable(() => import('../components/molecules/ShopByBrand/brand'));
const BrandGoldRings = loadable(() => import('./molecules/ShopByBrand/brandgoldrings'));
const ShopForKids = loadable(() => import('../components/ShopGender/ShopForKids'));
const KidsProductDetails = loadable(() => import('../components/ShopGender/ShopForKids/productdetails'));
const Gifts = loadable(() => import('../components/Gifts'));
const BuyACard = loadable(() => import('../components/Gifts/buyacard'));
const GiftsReview = loadable(() => import('../components/Gifts/giftsreview'));
const GiftsBuyCardThankYou = loadable(() => import('../components/Gifts/thankyou'));
const GiftACard = loadable(() => import('../components/Gifts/giftacard'));
const GiftReview = loadable(() => import('../components/Gifts/giftreview'));
const BuyACardCustomised = loadable(() => import('../components/Gifts/buyacardcustomised'));
const CustomiseGiftsReview = loadable(() => import('../components/Gifts/customisegiftsreview'));
const ProductDetails = loadable(() => import('./Pages/ProductDtails'));
const PrivateRoutes = loadable(() => import('../services/router/privateReoute'));
const PublicRoutes = loadable(() => import('../services/router/publicReoute'));
const Login = loadable(() => import('../components/molecules/Login'));
const Home = loadable(() => import('./Pages/Home'));
const Products = loadable(() => import('./Pages/products'));
const Header = loadable(() => import('../shared/header'));


const Router = () => {

  const router = useLocation()
  const dispatch = useDispatch()

  const { isActive } = useSelector((state)  => state.cart);

  useEffect(() => {

    const appuser= loadState("appuser")
    const userIdExpiry = loadState("userIdExpiry")

    if(appuser && userIdExpiry){
      const data = {
        sessionId: appuser
      }
      dispatch(cartListAction(data))
    }else{
      const data = {
        sessionId: sessionId()
      }
      dispatch(cartListAction(data))
    }
  }, []);


  useEffect(() => {
    if(router.pathname==="/checkout"){
      document.body.classList.add('checkout');
    }
    else if(router.pathname==="/"){
        document.body.classList.add('home');
    }
    else if(router.pathname==="/product-details" || router.pathname==="/shop-for-women/product-details" || router.pathname==="/shop-for-men/product-details" || router.pathname==="/shop-for-kids/product-details" || router.pathname==="/wedding-jewelry/product-details" || router.pathname==="/modern-manglsutra/product-details" || router.pathname==="/engagement-rings/product-details" || router.pathname==="/everyday-wear-bracelets/product-details" || router.pathname==="/gorgeous-studs/product-details" || router.pathname==="/coins-bar/silver-coins-bar/silver-coins" || router.pathname==="/gold/rings/engagement/product-details" || router.pathname==="/diamond/rings/engagement/product-details" || router.pathname==="/gemstones/rings/engagement/product-details" || router.pathname==="/platinum/rings/engagement/product-details" || router.pathname==="/silver/rings/engagement/product-details" || router.pathname==="/solitaire/rings/engagement/product-details" || router.pathname==="/shop-by-new-arrivals/product-details"){
      document.body.classList.add('product_details');
    }
    else if(router.pathname==="/coins-bar/gold-coins-bar/gold-coins"){
      document.body.classList.add('gold_product_details');
    }
    else if(router.pathname==="/shop-by-brand/jewels-box/gold/rings/gold-ring"){
      document.body.classList.add('gold_product_details');
    }
    else if(router.pathname==="/checkoutreview"){
      document.body.classList.add('checkout');
    }
    else if(router.pathname==="/sell-on-summera"){
        document.body.classList.add('sellOnSummera');
    }
    else if(router.pathname==="/gifts/buy-a-card"){
        document.body.classList.add('buyacard');
    }
    else if(router.pathname==="/gifts/buy-a-card/review"){
        document.body.classList.add('giftsreview');
    }
    else if(router.pathname==="/gifts/gift-a-card/review"){
        document.body.classList.add('giftreview');
    }
    else if(router.pathname==="/gifts/buy-a-card-customised"){
        document.body.classList.add('buyacard');
    }
    else if(router.pathname==="/gifts/buy-a-card-customised/review"){
        document.body.classList.add('customisegiftsreview');
    }
    else if(router.pathname==="/myaccount"){
        document.body.classList.add('myaccount');
    }else if(router.pathname==="/sell-on-summera/review"){
      document.body.classList.add('membership-review');
    } 
    else if(router.pathname==="/sell-on-summera/subscription-plan"){
        document.body.classList.add('subscription-task');
    }
    else if(router.pathname==="/sell-on-summera/review"){
        document.body.classList.add('membership-review');
    } 
    else if(router.pathname==="/sell-on-summera/subscription-plan"){
        document.body.classList.add('subscription-task');
    } else{
      document.body.classList.add('product_details');
      document.body.classList.remove('home');
      document.body.classList.remove('checkout');
      document.body.classList.remove('sellOnSummera');
      document.body.classList.remove('gold_product_details');
      document.body.classList.remove('buyacard');
      document.body.classList.remove('giftreview');
      document.body.classList.remove('customisegiftsreview');
      document.body.classList.remove('myaccount');
      document.body.classList.remove('subscription-task');
      document.body.classList.remove('membership-review');
    }
  })
  

  return (
    <React.Fragment>
      <Header />
      <Mycart isActive={isActive} />
      <Routes>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<Home />} path="/" exact  />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<Products />} path="/:slug" exact />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<Products />} path="/:slug/:slug1" exact />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<Login />} path="/login" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element= {<Contact />} path="/contact-us" exact />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<ReviewCart />} path="/review-cart" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<Registration />} path="/registration" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<Thank />} path="/thank-you" />
        </Route>
        {/* <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<OrdersAndReturns />} path="/orders-and-returns" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<PaymentPolicy />} path="/payment-policy" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<ShippingPolicy />} path="/shipping-policy" />
        </Route>
         <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<TermsAndConditions />} path="/terms-and-conditions" />
        </Route> 
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<PrivacyPolicy />} path="/privacy-policy" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<CookiePolicy />} path="/cookie-policy" />
        </Route> */}
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<Faqs />} path="/faqs" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<RequestVideoCall />} path="/request-video-call" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<ThankyouForTryAtHome />} path="/thankyou-for-tryathome" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<BookingInformation />} path="/booking-information" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<ScheduleYourAppointment />} path="/schedule-your-appointment" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<VideoCallThankYou />} path="/video-call-thank-you" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
            <Route element={<CustomerSays />} path="/customer-says" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<CustomerService />} path="/customer-service" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<AboutSummera />} path="/about-summera" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<FindStore />} path="/find-store" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<WhatsNew />} path="/whats-new" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<SellOnSummera />} path="/sell-on-summera" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<SubscriptionPlan />} path="/sell-on-summera/subscription-plan" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<SellOnSummeraReview />} path="/sell-on-summera/review" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<SubscriptionThanks />} path="/sell-on-summera/thankyou" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<Customised />} path="/customised" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<CustomisedRequestThankYou />} path="/customise-request/thank-you" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<JewelleryCareGuide />} path="/jewellery-care-guide" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<GemstoneCareGuide />} path="/jewellery-care-guide/gemstone-care-guide" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
            <Route element={<DiamondCareGuide />} path="/jewellery-care-guide/diamond-care-guide" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
            <Route element={<CustomiseRequest />} path="/customise-request" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<ShopByBrand />} path="/shop-by-brand" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<Brand />} path="/shop-by-brand/jewels-box" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<BrandGoldRings />} path="/shop-by-brand/jewels-box/gold" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<ShopForKids />} path="/shop-for-kids" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<KidsProductDetails />} path="/shop-for-kids/product-details" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<Gifts />} path="/gifts" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
            <Route element={<BuyACard />} path="/gifts/buy-a-card" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
           <Route element={<GiftsReview />} path="/gifts/buy-a-card/review" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<GiftsBuyCardThankYou />} path="/gifts/buy-a-card/thank-you" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<BuyACardCustomised />} path="/gifts/buy-a-card-customised" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<VideoCallThankYou />} path="/gifts/thank-you" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<GiftACard />} path="/gifts/gift-a-card" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<GiftReview />} path="/gifts/gift-a-card/review" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<CustomiseGiftsReview />} path="/gifts/buy-a-card-customised/review" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<ProductDetails />} path="/product-details/:slugs" />
        </Route>
        <Route element={<PublicRoutes isAuthenticated={false} />}>
          <Route element={<TermsAndConditionsL />} path="/t&c/:slug" />
        </Route>

        <Route element={<PrivateRoutes isAuthenticated={true} />}>
          <Route element={<Checkout />} path="/checkout" />
        </Route>
        <Route element={<PrivateRoutes isAuthenticated={true} />}>
          <Route element={<CheckoutReview />} path="/checkoutreview" />
        </Route>
        <Route element={<PrivateRoutes isAuthenticated={true} />}>
          <Route element={<Payment />} path="/payment" />
        </Route>
        <Route element={<PrivateRoutes isAuthenticated={true} />}>
          <Route element={<Thankyou />} path="/thankyou" />
        </Route>
        <Route element={<PrivateRoutes isAuthenticated={true} />}>
          <Route element={<MyAccount />} path="/myaccount" />
        </Route>
          <Route element={<NotFound />} path="*" />
      </Routes>
      <Footer />
    </React.Fragment>
  )
}

export default Router;