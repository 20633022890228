export const LOGIN = "LOGIN"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILED = "LOGIN_FAILED"

export const GENERATE_OTP = "GENERATE_OTP"
export const GENERATE_OTP_SUCCESS = "GENERATE_OTP_SUCCESS"
export const GENERATE_OTP_FAILDE = "GENERATE_OTP_FAILDE"

export const USER_OTP_VERIFICATION = "USER_OTP_VERIFICATION"
export const OTP_VERIFICATION_SUCCESS = "OTP_VERIFICATION_SUCCESS"
export const OTP_VERIFICATION_FAILDE = "OTP_VERIFICATION_FAILDE"


export const FETCH_REF_TOKEN = "FETCH_REF_TOKEN"
export const REF_TOKEN_RESPONSE = "REF_TOKEN_RESPONSE"
export const REF_TOKEN_FAILDE = "REF_TOKEN_FAILDE"