import { put } from 'redux-saga/effects';
import loginApi from "../services/loginApi";
import { FETCH_MEMBER_SHIP_RESPONSE, FETCH_MEMBER_SHIP_FAILED, PARTNER_SUBSCRIPTION_RESPONSE, PARTNER_SUBSCRIPTION_FAILED, SUBSCRIPTION_ORDERDATA_RESPONSE, SUBSCRIPTION_ORDERDATA_FAILED } from '../redux/types/verificationType';
import { RESET_LOADER, START_LOADING } from '../redux/types/loader';

let api = new loginApi();

export function* panVerificationSaga({ payload, resolve }) {

    try {
        let data = yield api.PanVarification(payload);
        
        if (data.status_code === 200) {
            resolve && resolve(data)
            console.log("status_codestatus_code", data)
        }
        else {
            resolve && resolve(data)
        }
    } catch (e) {
        alert(e)
    }
}


export function* memberShipSaga({ payload, resolve }) {

    try {
        let data = yield api.MemberSubscription(payload);
        if (data.status === 200) {
            
            yield put({ type: FETCH_MEMBER_SHIP_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            console.log("FETCH_MEMBER_SHIP_FAILED", data);
            yield put({ type: FETCH_MEMBER_SHIP_FAILED, payload: data })
            resolve && resolve(data)
        }
    } catch (e) {
        yield put({ type: FETCH_MEMBER_SHIP_FAILED, payload: e })

    }
}

export function* partnerShipSaga({ payload, resolve }) {
    try {
        let data = yield api.AddpartnerSubscription(payload);
        if (data.status === 200) {
            yield put({ type: PARTNER_SUBSCRIPTION_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            yield put({ type: PARTNER_SUBSCRIPTION_FAILED, payload: data })
            resolve && resolve(data)
        }
    } catch (e) {
        yield put({ type: PARTNER_SUBSCRIPTION_FAILED, payload: e })
    }
}


export function* subscriptionOrderSaga({ payload, resolve }) {
    try {
        yield put({ type: START_LOADING, isLoading: true })
        let data = yield api.PartnerSubscriptionOrders(payload);

        if (data.status === 200) {
            yield put({ type: SUBSCRIPTION_ORDERDATA_RESPONSE, payload: data })
            resolve && resolve(data)
            yield put({ type: RESET_LOADER, isLoading: false })
        }
        else {
            yield put({ type: SUBSCRIPTION_ORDERDATA_FAILED, payload: data })
            resolve && resolve(data)
            yield put({ type: RESET_LOADER, isLoading: false })
        }
    } catch (e) {
        yield put({ type: SUBSCRIPTION_ORDERDATA_FAILED, payload: e })
    }
}