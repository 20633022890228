import { FETCH_MEMBER_SHIP_RESPONSE,FETCH_MEMBER_SHIP_FAILED, PARTNER_SUBSCRIPTION_RESPONSE,PARTNER_SUBSCRIPTION_FAILED, SUBSCRIPTION_ORDERDATA_RESPONSE, SUBSCRIPTION_ORDERDATA_FAILED } from "../types/verificationType";

const initialState = {
    memberShip: null,
    orderId: null,
    orderData: null,
}
const  reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MEMBER_SHIP_RESPONSE :
            const { shipsubscriptionplan } = action.payload.data
            return {...state, memberShip: shipsubscriptionplan }
        case FETCH_MEMBER_SHIP_FAILED:
            return {...state, memberShip: null }

        case PARTNER_SUBSCRIPTION_RESPONSE :
            const { orderId } = action.payload
            return {...state, orderId }
        case PARTNER_SUBSCRIPTION_FAILED:
            return {...state, orderId: null }

        case SUBSCRIPTION_ORDERDATA_RESPONSE :
            const { data } = action.payload
            return {...state, orderData: data }
        case SUBSCRIPTION_ORDERDATA_FAILED:
            return {...state, orderData: null }
        
        default:
            return state;
    }
}

export default reducer